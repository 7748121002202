import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { useForm, ValidationError } from '@formspree/react';
import { MailIcon, PhoneIcon } from "@heroicons/react/outline";


function ContactForm() {
  const [state, handleSubmit] = useForm("xlezknee");
  if (state.succeeded) {
      return <p>Thank you for contacting us! We'll get back to you within 48 hours.</p>;
  }
  return (
      <form onSubmit={handleSubmit} className="grid grid-cols-1 gap-y-6">

      <label htmlFor="full-name" className="sr-only">
        Full name
      </label>
      <input
        type="text"
        name="full-name"
        id="full-name"
        autoComplete="name"
        className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
        placeholder="Full name"
        required
      />
      <label htmlFor="full-name" className="sr-only">
        Email
      </label>
      <input
        id="email"
        name="email"
        type="email"
        autoComplete="email"
        className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
        placeholder="Email"
        name="_replyto"
        required
      />
      <ValidationError
        prefix="Email"
        field="email"
        errors={state.errors}
      />
      <label htmlFor="full-name" className="sr-only">
        Phone
      </label>
      <input
        type="text"
        name="phone"
        id="phone"
        autoComplete="tel"
        className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
        placeholder="Phone"
        required
      />
      <label htmlFor="message" className="sr-only">
        Message
      </label>
      <textarea
        id="message"
        name="message"
        rows={4}
        className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border border-gray-300 rounded-md"
        placeholder="Message"
        defaultValue={""}
        required
      />
      <ValidationError
        prefix="Message"
        field="message"
        errors={state.errors}
      />
      <button
        type="submit"
        className="inline-flex justify-center py-3 px-6 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-green-500 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
        disabled={state.submitting}>
        Submit
      </button>

    </form>
  );
}

export default function Example() {
  return (
    <Layout>
      <Seo title="Contact Us" />
      <div className="relative bg-white">
        <div className="absolute inset-0">
          <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50" />
        </div>
        <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
          <div className="bg-gray-50 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
            <div className="max-w-lg mx-auto">
              <h1 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
                Get in touch
              </h1>
              <p className="mt-3 text-lg leading-6 text-gray-500"></p>
              <dl className="mt-4 text-base text-gray-500">
              {/*
                <div>
                  <dt className="sr-only">Postal address</dt>
                  <dd>
                    <p>1411 4th Ave suite 1000</p>
                    <p>Seattle, WA 98101</p>
                  </dd>
                </div>
                */}
                <div className="mt-4">
                  <dt className="sr-only">Phone number</dt>
                  <dd className="flex">
                    <PhoneIcon
                      className="flex-shrink-0 h-6 w-6 text-gray-400"
                      aria-hidden="true"
                    />

                    <span className="ml-3"><a href="tel:877-578-3265">(877) 578-3265</a></span>
                  </dd>
                </div>
                <div className="mt-3">
                  <dt className="sr-only">Email</dt>
                  <dd className="flex">
                    <MailIcon
                      className="flex-shrink-0 h-6 w-6 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="ml-3"><a href="mailto:info@steamseattle.com">info@steamseattle.com</a></span>
                  </dd>
                </div>
              </dl>
              {/*
            <p className="mt-6 text-base text-gray-500">
              Looking for careers?{' '}
              <a href="#" className="font-medium text-gray-700 underline">
                View all job openings
              </a>
              .
            </p>
            */}
            </div>
          </div>
          <div className="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
            <div className="max-w-lg mx-auto lg:max-w-none">
            <ContactForm />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
